import React from "react";

type DisplayCardProps = React.HTMLAttributes<HTMLDivElement> & {
  title: string;
  description?: string;
};

const DisplayCard: React.FC<DisplayCardProps> = ({
  title,
  description,
  children,
  className = "",
  style,
  ...rest
}) => {
  return (
    <div
      className={`relative m-4 aspect-[4/3] overflow-hidden rounded-2xl border-neutral-800 bg-transparent md:aspect-auto md:p-0 ${className}`}
      style={style}
      {...rest}
    >
      <div className="absolute inset-0 bg-neutral-600 bg-opacity-10 backdrop-blur-md"></div>

      <div className="relative flex h-full w-full flex-col overflow-hidden">
        <div className="relative flex-1 overflow-hidden">
          <div className="absolute inset-0 h-full w-full opacity-10 blur-2xl brightness-50 filter">
            {children}
          </div>
          <div>{children}</div>
        </div>
        <div className="w-full bg-opacity-75 p-4 md:absolute md:bottom-0 md:w-1/2 md:p-8">
          <h3 className="break-words text-xl font-semibold leading-snug text-neutral-200 md:text-3xl">
            {title}
          </h3>
          {description && (
            <p className="mt-2 break-words text-lg leading-relaxed text-neutral-200 md:text-2xl">
              {description}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default DisplayCard;
