import { line } from "d3-shape";
import { scaleLinear } from "d3-scale";

interface MeandererConfig {
  height: number;
  path: string;
  threshold?: number;
  width: number;
}

class Meanderer {
  private container: HTMLElement | null;
  private height: number;
  private path: string;
  private threshold: number;
  private width: number;
  private aspect_ratio: number;
  private path_data: [number, number][];
  private maximums: [number, number];
  private range_ratios: [number, number];

  constructor({ height, path, threshold = 0.2, width }: MeandererConfig) {
    this.container = null;
    this.height = height;
    this.path = path;
    this.threshold = threshold;
    this.width = width;
    this.aspect_ratio = width / height;
    this.path_data = this.convertPathToData(path);
    this.maximums = this.getMaximums(this.path_data);
    this.range_ratios = this.getRatios(this.maximums, width, height);
  }

  private getMaximums(data: [number, number][]): [number, number] {
    const X_POINTS = data.map((point) => point[0]);
    const Y_POINTS = data.map((point) => point[1]);
    return [Math.max(...X_POINTS), Math.max(...Y_POINTS)];
  }

  private getRatios(
    maxs: [number, number],
    width: number,
    height: number,
  ): [number, number] {
    return [maxs[0] / width, maxs[1] / height];
  }

  private convertPathToData(path: string): [number, number][] {
    const svgContainer = document.createElement("div");
    svgContainer.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg"><path d="${path}"/></svg>`;
    const pathElement = svgContainer.querySelector("path");
    if (!pathElement) {
      throw new Error("Invalid SVG Path");
    }

    const data: [number, number][] = [];
    for (let p = 0; p < pathElement.getTotalLength(); p++) {
      const { x, y } = pathElement.getPointAtLength(p);
      data.push([x, y] as [number, number]);
    }
    return data;
  }

  public generatePath(containerWidth: number, containerHeight: number): string {
    const {
      height,
      width,
      aspect_ratio: aspectRatio,
      path_data: data,
      maximums: [maxWidth, maxHeight],
      range_ratios: [widthRatio, heightRatio],
      threshold,
    } = this;

    const OFFSETS: [number, number] = [0, 0];
    const newAspectRatio = containerWidth / containerHeight;

    if (Math.abs(newAspectRatio - aspectRatio) > threshold) {
      if (width < height) {
        const ratio = (height - width) / height;
        OFFSETS[0] = (ratio * containerWidth) / 2;
      } else {
        const ratio = (width - height) / width;
        OFFSETS[1] = (ratio * containerHeight) / 2;
      }
    }

    const xScale = scaleLinear()
      .domain([0, maxWidth])
      .range([OFFSETS[0], containerWidth * widthRatio - OFFSETS[0]]);
    const yScale = scaleLinear()
      .domain([0, maxHeight])
      .range([OFFSETS[1], containerHeight * heightRatio - OFFSETS[1]]);

    const SCALED_POINTS: [number, number][] = data.map((POINT) => [
      xScale(POINT[0]),
      yScale(POINT[1]),
    ]);

    const lineGenerator = line<[number, number]>();
    return lineGenerator(SCALED_POINTS) || ""; // Ensure a string is returned even if lineGenerator returns null
  }
}

export default Meanderer;
