import React from "react";
import {
  IconLayersSubtract,
  IconConeFilled,
  IconPrinter,
  IconPointerFilled,
} from "@tabler/icons-react";

import contentBG from "../../../assets/images/features/content_bg.png";

type Layer = {
  name: string;
  used: boolean;
  visibility: boolean;
};

type Scene = {
  name: string;
  layers: Layer[];
};

type Output = {
  basePath: string;
  filePath: string;
  used: boolean;
};

type OutputNode = {
  name: string;
  outputs: Output[];
};

type IconGridProps = {
  items: any[];
  icon: React.ElementType;
  getItemStyle?: (item: any) => string;
  iconSize?: number;
  onItemClick?: (item: any, index: number) => void;
};

const getLayerStyle = (used: boolean) =>
  used ? "bg-neutral-600 text-neutral-300" : "bg-neutral-800 text-neutral-600";

const IconGrid = React.forwardRef<HTMLDivElement, IconGridProps>(
  (
    { items, icon: IconComponent, getItemStyle, iconSize = 32, onItemClick },
    ref,
  ) => {
    return (
      <div className="grid grid-cols-4 gap-2 text-xs" ref={ref}>
        {items.map((item, index) => (
          <div
            key={index}
            className="icon-grid-item flex items-center justify-center rounded-lg"
            onClick={() => onItemClick && onItemClick(item, index)}
            style={{ cursor: "pointer" }}
          >
            <div
              className={`rounded-xl p-2 ${
                getItemStyle ? getItemStyle(item.used) : ""
              }`}
            >
              <IconComponent size={iconSize} />
            </div>
          </div>
        ))}
      </div>
    );
  },
);

type SceneListProps = {
  scenes: Scene[];
  setScenes: React.Dispatch<React.SetStateAction<Scene[]>>;
  onIconGridRefs?: (refs: React.RefObject<HTMLDivElement>[]) => void;
};

const SceneList: React.FC<SceneListProps> = ({
  scenes,
  setScenes,
  onIconGridRefs,
}) => {
  const iconGridRefs = React.useRef<React.RefObject<HTMLDivElement>[]>([]);

  React.useEffect(() => {
    if (onIconGridRefs) {
      onIconGridRefs(iconGridRefs.current);
    }
  }, [onIconGridRefs]);

  const handleLayerClick = (sceneIndex: number, layerIndex: number) => {
    setScenes((prevScenes) => {
      const newScenes = [...prevScenes];
      const scene = { ...newScenes[sceneIndex] };
      const layers = [...scene.layers];
      layers[layerIndex] = {
        ...layers[layerIndex],
        used: !layers[layerIndex].used,
      };
      scene.layers = layers;
      newScenes[sceneIndex] = scene;
      return newScenes;
    });
  };

  return (
    <div className="relative">
      {scenes.map((scene, sceneIndex) => {
        if (!iconGridRefs.current[sceneIndex]) {
          iconGridRefs.current[sceneIndex] = React.createRef<HTMLDivElement>();
        }

        return (
          <div key={scene.name} className="mb-2 flex flex-col space-y-2">
            <div className="flex items-center space-x-2 rounded-xl bg-neutral-800 p-1">
              <h3 className="flex items-center rounded-lg bg-neutral-700 px-2 font-semibold text-neutral-200">
                <IconConeFilled size={18} className="mr-2" />
                {scene.name}
              </h3>
              <h3 className="font-semibold text-neutral-400">Render Layers:</h3>
            </div>
            <IconGrid
              items={scene.layers}
              icon={IconLayersSubtract}
              getItemStyle={getLayerStyle}
              onItemClick={(item, index) => handleLayerClick(sceneIndex, index)}
              ref={iconGridRefs.current[sceneIndex]}
            />
          </div>
        );
      })}
    </div>
  );
};

type OutputNodeListProps = {
  outputNodes: OutputNode[];
  setOutputNodes: React.Dispatch<React.SetStateAction<OutputNode[]>>;
  onIconGridRefs?: (refs: React.RefObject<HTMLDivElement>[]) => void;
};

const OutputNodeList: React.FC<OutputNodeListProps> = ({
  outputNodes,
  setOutputNodes,
  onIconGridRefs,
}) => {
  const iconGridRefs = React.useRef<React.RefObject<HTMLDivElement>[]>([]);

  React.useEffect(() => {
    if (onIconGridRefs) {
      onIconGridRefs(iconGridRefs.current);
    }
  }, [onIconGridRefs]);

  const handleOutputClick = (nodeIndex: number, outputIndex: number) => {
    setOutputNodes((prevOutputNodes) => {
      const newOutputNodes = [...prevOutputNodes];
      const node = { ...newOutputNodes[nodeIndex] };
      const outputs = [...node.outputs];
      outputs[outputIndex] = {
        ...outputs[outputIndex],
        used: !outputs[outputIndex].used,
      };
      node.outputs = outputs;
      newOutputNodes[nodeIndex] = node;
      return newOutputNodes;
    });
  };

  return (
    <div>
      {outputNodes.map((outputNode, nodeIndex) => {
        if (!iconGridRefs.current[nodeIndex]) {
          iconGridRefs.current[nodeIndex] = React.createRef<HTMLDivElement>();
        }

        return (
          <div key={outputNode.name} className="mb-2 flex flex-col space-y-2">
            <div className="flex items-center space-x-2 rounded-xl bg-neutral-800 p-1">
              <h3 className="flex items-center rounded-lg bg-neutral-700 px-2 font-semibold text-neutral-200">
                <IconPrinter size={18} className="mr-2" />
                {outputNode.name}
              </h3>
              <h3 className="font-semibold text-neutral-400">Outputs:</h3>
            </div>
            <IconGrid
              items={outputNode.outputs}
              icon={IconPrinter}
              getItemStyle={getLayerStyle}
              onItemClick={(item, index) => handleOutputClick(nodeIndex, index)}
              ref={iconGridRefs.current[nodeIndex]}
            />
          </div>
        );
      })}
    </div>
  );
};

interface ContentManagerMinimalProps {
  scale: number;
  active: boolean;
}

const ContentManagerMinimal: React.FC<ContentManagerMinimalProps> = ({
  scale,
  active,
}) => {
  const [scenes, setScenes] = React.useState<Scene[]>([
    {
      name: "Scene",
      layers: [
        { name: "ViewLayer", used: true, visibility: true },
        { name: "ViewLayer_001", used: false, visibility: true },
        { name: "ViewLayer_002", used: false, visibility: true },
        { name: "ViewLayer_003", used: false, visibility: true },
        { name: "ViewLayer_004", used: false, visibility: true },
      ],
    },
    {
      name: "Scene.001",
      layers: [
        { name: "ViewLayer", used: true, visibility: false },
        { name: "LayerB", used: true, visibility: false },
      ],
    },
  ]);

  const [outputNodes, setOutputNodes] = React.useState<OutputNode[]>([
    {
      name: "Composite",
      outputs: [{ basePath: "/", filePath: "0001.png", used: true }],
    },
    {
      name: "File Output",
      outputs: [
        { basePath: "/ao/", filePath: "ao", used: true },
        { basePath: "/shadow/", filePath: "shadow", used: false },
        { basePath: "/normal/", filePath: "normal", used: true },
      ],
    },
  ]);

  const containerRef = React.useRef<HTMLDivElement>(null);
  const [cursorPosition, setCursorPosition] = React.useState({ x: 0, y: 0 });
  const currentCursorPosition = React.useRef<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });

  const targetItemsRef = React.useRef<
    {
      x: number;
      y: number;
      width: number;
      height: number;
      type: "scene" | "output";
      sceneIndex?: number;
      outputIndex?: number;
      itemIndex: number;
    }[]
  >([]);

  const sceneIconGridRefs = React.useRef<React.RefObject<HTMLDivElement>[]>([]);
  const outputIconGridRefs = React.useRef<React.RefObject<HTMLDivElement>[]>(
    [],
  );

  // Collect positions of items
  React.useLayoutEffect(() => {
    if (!containerRef.current) return;
    const containerRect = containerRef.current.getBoundingClientRect();

    const newTargetItems: typeof targetItemsRef.current = [];

    sceneIconGridRefs.current.forEach((iconGridRef, sceneIndex) => {
      if (iconGridRef.current) {
        const items = iconGridRef.current.querySelectorAll(".icon-grid-item");
        items.forEach((itemElement, itemIndex) => {
          const rect = itemElement.getBoundingClientRect();
          const adjustedX = (rect.left - containerRect.left) / scale;
          const adjustedY = (rect.top - containerRect.top) / scale;

          newTargetItems.push({
            x: adjustedX,
            y: adjustedY,
            width: rect.width / scale,
            height: rect.height / scale,
            type: "scene",
            sceneIndex,
            itemIndex,
          });
        });
      }
    });

    outputIconGridRefs.current.forEach((iconGridRef, outputIndex) => {
      if (iconGridRef.current) {
        const items = iconGridRef.current.querySelectorAll(".icon-grid-item");
        items.forEach((itemElement, itemIndex) => {
          const rect = itemElement.getBoundingClientRect();
          const adjustedX = (rect.left - containerRect.left) / scale;
          const adjustedY = (rect.top - containerRect.top) / scale;

          newTargetItems.push({
            x: adjustedX,
            y: adjustedY,
            width: rect.width / scale,
            height: rect.height / scale,
            type: "output",
            outputIndex,
            itemIndex,
          });
        });
      }
    });

    targetItemsRef.current = newTargetItems;

    // Initialize cursor position to a random target
    if (
      newTargetItems.length > 0 &&
      currentCursorPosition.current.x === 0 &&
      currentCursorPosition.current.y === 0
    ) {
      const initialTarget =
        newTargetItems[Math.floor(Math.random() * newTargetItems.length)];
      const initialX = initialTarget.x + initialTarget.width / 2;
      const initialY = initialTarget.y + initialTarget.height / 2;

      currentCursorPosition.current = { x: initialX, y: initialY };
      setCursorPosition({ x: initialX, y: initialY });
    }
  }, [scale]);

  // Animate cursor movement and simulate clicks
  React.useEffect(() => {
    let isMounted = true;

    const animateCursor = async () => {
      while (isMounted) {
        const targetItems = targetItemsRef.current;
        if (targetItems.length === 0) {
          await new Promise((resolve) => setTimeout(resolve, 500));
          continue;
        }

        const randomIndex = Math.floor(Math.random() * targetItems.length);
        const targetItem = targetItems[randomIndex];

        const targetX = targetItem.x + targetItem.width / 2;
        const targetY = targetItem.y + targetItem.height / 2;

        await moveCursor(
          currentCursorPosition.current.x,
          currentCursorPosition.current.y,
          targetX,
          targetY,
        );

        currentCursorPosition.current = { x: targetX, y: targetY };

        // Simulate click after movement
        if (
          targetItem.type === "scene" &&
          targetItem.sceneIndex !== undefined
        ) {
          setScenes((prevScenes) => {
            const newScenes = [...prevScenes];
            const scene = { ...newScenes[targetItem.sceneIndex!] };
            const layers = [...scene.layers];
            layers[targetItem.itemIndex] = {
              ...layers[targetItem.itemIndex],
              used: !layers[targetItem.itemIndex].used,
            };
            scene.layers = layers;
            newScenes[targetItem.sceneIndex!] = scene;
            return newScenes;
          });
        } else if (
          targetItem.type === "output" &&
          targetItem.outputIndex !== undefined
        ) {
          setOutputNodes((prevOutputNodes) => {
            const newOutputNodes = [...prevOutputNodes];
            const node = { ...newOutputNodes[targetItem.outputIndex!] };
            const outputs = [...node.outputs];
            outputs[targetItem.itemIndex] = {
              ...outputs[targetItem.itemIndex],
              used: !outputs[targetItem.itemIndex].used,
            };
            node.outputs = outputs;
            newOutputNodes[targetItem.outputIndex!] = node;
            return newOutputNodes;
          });
        }

        // Wait for a bit before moving to the next target
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
    };

    const moveCursor = (
      startX: number,
      startY: number,
      endX: number,
      endY: number,
    ) => {
      return new Promise<void>((resolve) => {
        const duration = 1000; // 1 second
        const startTime = performance.now();

        const animate = (currentTime: number) => {
          const elapsed = currentTime - startTime;
          const progress = Math.min(elapsed / duration, 1);

          const x = startX + (endX - startX) * easeInOutQuad(progress);
          const y = startY + (endY - startY) * easeInOutQuad(progress);

          setCursorPosition({ x, y });

          if (progress < 1) {
            requestAnimationFrame(animate);
          } else {
            resolve();
          }
        };

        requestAnimationFrame(animate);
      });
    };

    const easeInOutQuad = (t: number) => {
      return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
    };

    animateCursor();

    return () => {
      isMounted = false;
    };
  }, []); // Empty dependency array to run only once

  const Cursor: React.FC<{ x: number; y: number }> = ({ x, y }) => {
    return (
      <IconPointerFilled
        size={32}
        style={{
          position: "absolute",
          left: x,
          top: y,
          transform: "translate(-50%, -25%)",
          pointerEvents: "none",
          zIndex: 1000,
        }}
        color="white"
      />
    );
  };

  return (
    <div
      ref={containerRef}
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        transform: `scale(${scale})`,
        transformOrigin: "top left",
      }}
    >
      <div className="absolute flex w-[1600px] justify-end">
        {/* background is contentBG */}
        <div className="absolute inset-0 h-full w-full">
          <img src={contentBG} className="aspect-[8/3] h-full w-full" />
        </div>
        <div className="relative flex h-full w-1/3 flex-col border-l border-neutral-700 bg-neutral-800 p-2">
          <h1 className="text-center text-xl font-bold text-neutral-400">
            Content Manager
          </h1>
          <div className="mb-4 rounded-xl bg-neutral-900 p-2">
            <h2 className="mb-2 px-2 text-lg font-bold text-neutral-600">
              Scene Rendering
            </h2>
            <SceneList
              scenes={scenes}
              setScenes={setScenes}
              onIconGridRefs={(refs) => (sceneIconGridRefs.current = refs)}
            />
          </div>
          <div className="rounded-xl bg-neutral-900 p-2">
            <h2 className="mb-2 px-2 text-lg font-bold text-neutral-600">
              Render Outputs
            </h2>
            <OutputNodeList
              outputNodes={outputNodes}
              setOutputNodes={setOutputNodes}
              onIconGridRefs={(refs) => (outputIconGridRefs.current = refs)}
            />
          </div>
        </div>
      </div>
      <Cursor x={cursorPosition.x} y={cursorPosition.y} />
    </div>
  );
};

export default ContentManagerMinimal;
