import React, { useEffect } from "react";
import Hero from "./components/Hero";
import Features from "./components/Features";
import Pricing from "./components/feature_pages/Pricing";
import TotalFramesRendered from "./components/TotalFramesRendered";
import Opinions from "./components/Opinions";
import ParallaxCubes from "./components/ParallaxCubes";

import RenderViewerHero from "./components/feature_pages/RenderViewerHero";
import RenderViewerFeatures from "./components/feature_pages/RenderViewerFeatures";
import BlenderExtensionFeatures from "./components/feature_pages/BlenderExtensionFeatures";
import RenderNodePerformance from "./components/feature_pages/RenderNodePerformance";
import MachineInsights from "./components/feature_pages/MachineInsights";
import TransferManager from "./components/feature_pages/TransferManager";
import DeFi from "./components/feature_pages/DeFi";
import Footer from "./components/Footer";

import { ArrowUp } from "@phosphor-icons/react";

const App: React.FC = () => {
  const webUiSectionRef = React.useRef<HTMLDivElement>(null);
  const blenderExtensionSectionRef = React.useRef<HTMLDivElement>(null);
  const transferManagerSectionRef = React.useRef<HTMLDivElement>(null);
  const speedSectionRef = React.useRef<HTMLDivElement>(null);
  const moneySectionRef = React.useRef<HTMLDivElement>(null);

  const [showScrollToTop, setShowScrollToTop] = React.useState(false);

  useEffect(() => {
    const handleScroll = () => {
      // Set the button to be visible if the page is scrolled more than the viewport height
      if (window.scrollY > window.innerHeight) {
        setShowScrollToTop(true);
      } else {
        setShowScrollToTop(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="font-sans">
      <Hero
        moneySectionRef={moneySectionRef}
        speedSectionRef={speedSectionRef}
        transferManagerSectionRef={transferManagerSectionRef}
        webUiSectionRef={webUiSectionRef}
        blenderExtensionSectionRef={blenderExtensionSectionRef}
      />
      {/* <Features /> */}

      {/* <RenderViewerHero /> */}
      <RenderViewerFeatures ref={webUiSectionRef} />
      <BlenderExtensionFeatures ref={blenderExtensionSectionRef} />
      <TransferManager ref={transferManagerSectionRef} />
      <RenderNodePerformance ref={speedSectionRef} />
      <Pricing ref={moneySectionRef} />
      <MachineInsights />
      {/* <TotalFramesRendered /> */}
      {/* <Opinions /> */}
      {/* <ParallaxCubes /> */}
      <Footer />

      {showScrollToTop && (
        <button
          onClick={scrollToTop}
          className="fixed bottom-4 right-4 z-50 rounded-full p-2 text-neutral-200 transition duration-300"
          style={{ cursor: "pointer" }}
        >
          <ArrowUp size={24} />
        </button>
      )}
    </div>
  );
};

export default App;
