import React from "react";
import Navbar from "./Navbar";
import Carousel from "./Carousel";
import {
  MoneyWavy,
  Speedometer,
  ArrowsClockwise,
  Layout,
  Package,
} from "@phosphor-icons/react";
import TextCard from "./TextCard";
import HeatMap from "../assets/images/features/heatmap_full.png";
import Logo from "../assets/images/logo.png";

interface HeroProps {
  moneySectionRef: React.RefObject<HTMLDivElement>;
  speedSectionRef: React.RefObject<HTMLDivElement>;
  transferManagerSectionRef: React.RefObject<HTMLDivElement>;
  webUiSectionRef: React.RefObject<HTMLDivElement>;
  blenderExtensionSectionRef: React.RefObject<HTMLDivElement>;
}

const Hero: React.FC<HeroProps> = ({
  moneySectionRef,
  speedSectionRef,
  transferManagerSectionRef,
  webUiSectionRef,
  blenderExtensionSectionRef,
}) => {
  const handleClick = () => {
    const urlParams = new URLSearchParams({
      client_id: "WDY-5v2eTrE4hlqzLh2TRhsuiqNRj9VJ9TtriH0oOHk",
      redirect_uri: `${window.location.origin}/login`,
      response_type: "code",
      scope: "",
    }).toString();

    const API_BASE_URL = "https://cube.octa.computer";
    const CUBE_OAUTH_URL = `${API_BASE_URL}/oauth/authorize?${urlParams}`;

    window.location.href = CUBE_OAUTH_URL;
  };

  const scrollToRef = (ref: React.RefObject<HTMLDivElement>) => {
    console.log(ref.current); // Check if the ref is pointing to the correct element
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
    } else {
      console.log("Ref is not attached to a DOM element.");
    }
  };

  const slides = [
    <TextCard
      key={1}
      icon={<MoneyWavy size={32} />}
      accentClass="text-emerald-600"
      text={
        <span className="text-neutral-400">
          Never overspend on renders again. Pay
          <strong> 90% less</strong> than other render farms.
        </span>
      }
      onClick={() => scrollToRef(moneySectionRef)}
    />,
    <TextCard
      key={2}
      icon={<Speedometer size={32} />}
      accentClass="text-amber-600"
      text={
        <span className="text-neutral-400">
          Speed up your renders by over <strong>1000x</strong> compared to
          rendering at home.
        </span>
      }
      onClick={() => scrollToRef(speedSectionRef)}
    />,
    <TextCard
      key={3}
      icon={<ArrowsClockwise size={32} />}
      accentClass="text-sky-600"
      text={
        <span className="text-neutral-400">
          OctaRender feels like part of your pipeline with{" "}
          <strong>Seamless Uploads & Downloads.</strong>
        </span>
      }
      onClick={() => scrollToRef(transferManagerSectionRef)}
    />,
    <TextCard
      key={4}
      icon={<Layout size={32} />}
      accentClass="text-violet-eggplant-600"
      text={
        <span className="text-neutral-400">
          Stay on top of your renders with{" "}
          <strong>an interface built to assist</strong> you in managing your
          renders.
        </span>
      }
      onClick={() => scrollToRef(webUiSectionRef)}
    />,
    <TextCard
      key={5}
      icon={<Package size={32} />}
      accentClass="text-yellow-600"
      text={
        <span className="text-neutral-400">
          Robustly include <strong>Dependencies & Addons</strong> using the
          <strong> Blender Extension</strong>.
        </span>
      }
      onClick={() => scrollToRef(blenderExtensionSectionRef)}
    />,
  ];

  return (
    <section className="relative flex h-screen flex-col items-center justify-between overflow-hidden bg-transparent px-6 text-neutral-200">
      {/* Navbar */}
      {/* <Navbar /> */}

      <div className="absolute inset-0 md:hidden">
        <div
          className="h-full w-full opacity-50"
          style={{
            backgroundImage: `linear-gradient(to top, rgba(0,0,0,0), rgba(0,0,0,1)), url(${HeatMap})`,
            backgroundPosition: "center bottom",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        />
      </div>
      {/* Main Content */}
      <div className="relative flex w-full items-center justify-center pt-4">
        <div className="flex w-full max-w-[90rem] select-none items-center space-x-2">
          <img src={Logo} alt="Octa Render" className="h-8" />
          <h1 className="text-3xl font-bold leading-tight">OctaRender</h1>
        </div>
      </div>

      <div className="relative flex w-full max-w-[90rem] flex-col py-12">
        {/* Background image with gradient for mobile */}

        {/* Header Section */}
        <div className="relative flex flex-1 flex-col items-center justify-center md:flex-row md:justify-between">
          {/* Title and Buttons */}
          <div className="flex flex-col items-center space-y-4 text-left md:w-1/2 md:items-start">
            <h1 className="text-4xl font-bold leading-tight md:text-5xl lg:text-6xl">
              The Most{" "}
              <span className="text-violet-eggplant-600">Affordable</span> Farm
              is Designed to{" "}
              <span className="text-violet-eggplant-600">Speed</span> Up Your
              Workflow.
            </h1>
            <div className="flex w-full space-x-4">
              <button
                className="rounded-xl bg-violet-eggplant-600 px-6 py-3 text-xl font-semibold text-neutral-200 transition duration-200 hover:bg-violet-eggplant-700"
                onClick={handleClick}
              >
                Get Started
              </button>
              {/* <button className="rounded-xl border-2 border-neutral-200 px-6 py-3 font-semibold text-neutral-200 transition duration-200 hover:bg-neutral-200 hover:text-violet-eggplant-600">
                Learn More
              </button> */}
            </div>
          </div>
        </div>

        {/* Carousel */}
      </div>
      <section className="relative flex w-full flex-col items-center overflow-visible bg-transparent pb-6">
        <div className="w-full max-w-[90rem]">
          <Carousel slides={slides} />
        </div>
      </section>

      {/* Image for Desktop */}
      <div className="absolute right-0 top-0 z-[-1] hidden h-full md:block">
        <img
          src={HeatMap}
          alt="Hero Banner"
          className="h-full w-full object-cover object-right-top"
          style={{
            maskImage:
              "linear-gradient(to left, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))",
          }}
        />
      </div>
    </section>
  );
};

export default Hero;
