// Carousel.tsx
import React, { useCallback, useEffect, useState, useRef } from "react";
import useEmblaCarousel from "embla-carousel-react";
import { motion } from "framer-motion";
import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react";

interface CarouselProps {
  slides: React.ReactNode[];
}

const Carousel: React.FC<CarouselProps> = ({ slides }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isScrollable, setIsScrollable] = useState(false);

  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: false,
    align: "center",
    containScroll: "trimSnaps",
    inViewThreshold: 0.5,
    dragFree: false,
    active: true, // Set initial active state to true
  });

  const [canScrollPrev, setCanScrollPrev] = useState(false);
  const [canScrollNext, setCanScrollNext] = useState(false);

  const onSelect = useCallback(() => {
    if (!emblaApi) return;
    setCanScrollPrev(emblaApi.canScrollPrev());
    setCanScrollNext(emblaApi.canScrollNext());
  }, [emblaApi]);

  // Check if carousel is needed (i.e., slides exceed container width)
  const checkIfCarouselIsNeeded = useCallback(() => {
    if (!emblaApi || !containerRef.current) return;
    // const containerWidth = containerRef.current.clientWidth;
    // use window width instead of container width
    const containerWidth = window.innerWidth;
    const slideWidth = emblaApi.slideNodes()[0].getBoundingClientRect().width;
    const spaceBetweenSlides = 16; // Adjust this value based on your space-x-4 utility
    const totalSlidesWidth =
      slideWidth * slides.length + spaceBetweenSlides * (slides.length - 1);

    const isScrollable = totalSlidesWidth > containerWidth;

    setIsScrollable(isScrollable);
    // Re-initialize Embla with the new 'active' state
    emblaApi.reInit({
      loop: false,
      align: "center",
      containScroll: "trimSnaps",
      inViewThreshold: 0.5,
      dragFree: false,
      active: isScrollable,
    });

    // Update navigation button visibility
    setCanScrollPrev(emblaApi.canScrollPrev());
    setCanScrollNext(emblaApi.canScrollNext());
  }, [emblaApi, slides.length]);

  useEffect(() => {
    if (!emblaApi) return;

    emblaApi.on("select", onSelect);
    emblaApi.on("reInit", onSelect);

    onSelect();
    checkIfCarouselIsNeeded();

    window.addEventListener("resize", checkIfCarouselIsNeeded);
    return () => {
      window.removeEventListener("resize", checkIfCarouselIsNeeded);
      emblaApi.off("select", onSelect);
      emblaApi.off("reInit", onSelect);
    };
  }, [emblaApi, onSelect, checkIfCarouselIsNeeded]);

  return (
    <div className="relative w-full" ref={containerRef}>
      <div className="overflow-visible" ref={emblaRef}>
        <div className="flex items-center justify-start space-x-4">
          {slides.map((slide, index) => (
            <div
              key={index}
              className="aspect-[4/3] w-80 flex-none overflow-hidden rounded-lg border border-neutral-800 bg-neutral-400/5 p-4 filter backdrop-blur-lg"
            >
              {slide}
            </div>
          ))}
        </div>
      </div>
      {canScrollPrev && isScrollable && (
        <button
          onClick={() => emblaApi && emblaApi.scrollPrev()}
          className="absolute left-0 top-1/2 hidden -translate-y-1/2 transform rounded-full bg-transparent p-2 text-neutral-200 md:block"
        >
          <IconChevronLeft size={32} />
        </button>
      )}
      {canScrollNext && isScrollable && (
        <button
          onClick={() => emblaApi && emblaApi.scrollNext()}
          className="absolute right-0 top-1/2 hidden -translate-y-1/2 transform rounded-full bg-transparent p-2 text-neutral-200 md:block"
        >
          <IconChevronRight size={32} />
        </button>
      )}
    </div>
  );
};

export default Carousel;
