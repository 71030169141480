// components/KnowYourMachine.tsx
import React from "react";
import { Cpu, GraphicsCard, HardDrives } from "@phosphor-icons/react";
import TextCard from "../TextCard";
import Carousel from "../Carousel";

const knowYourMachineCards = [
  <TextCard
    key={1}
    icon={<Cpu size={32} />}
    accentClass="text-neutral-200"
    text={
      <span>
        Easily spot and resolve performance bottlenecks using
        <strong> node analytics</strong>.
      </span>
    }
  />,
  <TextCard
    key={2}
    icon={<GraphicsCard size={32} />}
    accentClass="text-neutral-200"
    text={
      <span>
        Ensure optimal <strong>GPU utilization</strong> to make sure you render
        as efficiently as possible.
      </span>
    }
  />,
  <TextCard
    key={3}
    icon={<HardDrives size={32} />}
    accentClass="text-neutral-200"
    text={
      <span>
        Frames are <strong>securely archived</strong> on a global, redundant
        CDN, ensuring no render loss for 30 days{" "}
        <strong>at no extra cost</strong>.
      </span>
    }
  />,
];

const KnowYourMachine: React.FC = () => (
  <div className="flex w-full flex-col items-center justify-center overflow-hidden pb-20">
    <section className="relative flex w-full flex-col items-center justify-center overflow-hidden">
      <div className="z-10 w-full max-w-[90rem] px-4">
        <h2 className="pb-4 text-left text-2xl text-neutral-400 md:text-4xl">
          <span className="font-bold text-neutral-200">Know Your Nodes.</span>{" "}
          Get machine insights to optimize utilization and performance.
        </h2>
      </div>

      <section className="flex w-full max-w-[90rem] flex-col items-start overflow-visible bg-transparent px-4 pt-4">
        <Carousel slides={knowYourMachineCards} />
      </section>
    </section>
  </div>
);

export default KnowYourMachine;
