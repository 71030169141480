// components/BlenderExtensionFeatures.tsx
import React, { useEffect, useRef, useState, forwardRef } from "react";
import DragAndDrop from "./blender_extension/DragAndDrop";
import Dependencies from "./blender_extension/Dependencies";
import Addons from "./blender_extension/Addons";
import ContentManagerMinimal from "./blender_extension/ContentManagerMinimal";
import FeatureCard from "../FeatureCard";
import { createPortal } from "react-dom";

const BlenderExtensionFeatures = forwardRef<HTMLDivElement>((props, ref) => {
  const [scale, setScale] = useState(1);
  const [closestFeatureId, setClosestFeatureId] = useState<string>("");
  const featureRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});

  const handleResize = () => {
    const parentWidth =
      featureRefs.current["feature3"]?.parentElement?.offsetWidth;
    if (parentWidth) {
      const desiredWidth = 1600; // Adjust this value if necessary
      setScale(parentWidth / desiredWidth);
    }
  };

  // Function to handle scroll events
  const handleScroll = () => {
    const distances: { id: string; distance: number }[] = [];
    const viewportHeight = window.innerHeight;

    for (const id in featureRefs.current) {
      const ref = featureRefs.current[id];
      if (ref) {
        const rect = ref.getBoundingClientRect();
        const distanceToCenter = Math.abs(
          rect.top + rect.height / 2 - viewportHeight / 2,
        );
        distances.push({ id, distance: distanceToCenter });
      }
    }

    if (distances.length > 0) {
      const closest = distances.reduce((prev, curr) =>
        curr.distance < prev.distance ? curr : prev,
      );
      // If distance is less than 1/4 of the viewport height
      if (closest.distance < viewportHeight / 4) {
        setClosestFeatureId(closest.id);
      } else {
        setClosestFeatureId("");
      }
    } else {
      setClosestFeatureId("");
    }
  };

  useEffect(() => {
    // Event listeners for resize and scroll
    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll);
    handleResize(); // Initial scaling adjustment
    handleScroll(); // Initial scroll position

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const setFeatureRef = (id: string) => (ref: HTMLDivElement | null) => {
    featureRefs.current[id] = ref;
  };

  // Create the dark overlay
  const overlay = (
    <div
      className="pointer-events-none fixed left-0 top-0 z-10 h-full w-full bg-neutral-950 transition-opacity duration-300"
      style={{ opacity: closestFeatureId !== "" ? 0.7 : 0 }}
    ></div>
  );

  return (
    <div
      className="flex w-full flex-col items-center justify-center pb-40"
      ref={ref}
    >
      {/* Render the overlay */}
      {createPortal(overlay, document.body)}
      <div className="flex w-full flex-col items-center justify-center">
        <section className="relative flex w-full flex-col items-center justify-center overflow-hidden">
          {/* Adjusted Title */}
          <h2
            className="z-[11] w-full max-w-[90rem] px-4 pb-12 pt-40 text-left text-2xl text-neutral-400 transition-opacity duration-300 md:pb-24 md:text-4xl"
            style={{ opacity: closestFeatureId !== "" ? 1 : 0.3 }}
          >
            <span className="font-bold text-neutral-200">
              From your project to the farm.
            </span>{" "}
            The Blender extension is packed with features to make your workflow
            easier and more efficient.
          </h2>

          {/* Adjusted Feature Cards Container */}
          <div className="px:0 flex w-full max-w-[90rem] flex-col space-y-8 md:space-y-12 md:px-4">
            {/* Feature 1 */}
            <div ref={setFeatureRef("feature1")}>
              <FeatureCard
                id="feature1"
                title="Easy Installation"
                description="Simply drag and drop the extension from the website into Blender to install."
                isClosest={closestFeatureId === "feature1"}
                opacity={closestFeatureId === "feature1" ? 1 : 0.3}
              >
                <DragAndDrop
                  scale={scale}
                  active={closestFeatureId === "feature1"}
                />
              </FeatureCard>
            </div>

            {/* Feature 2 */}
            <div ref={setFeatureRef("feature2")}>
              <FeatureCard
                id="feature2"
                title="No File Left Behind"
                description="Never worry about missing files—every type, every location, always included automatically."
                isClosest={closestFeatureId === "feature2"}
                opacity={closestFeatureId === "feature2" ? 1 : 0.3}
              >
                <Dependencies
                  scale={scale}
                  active={closestFeatureId === "feature2"}
                />
              </FeatureCard>
            </div>

            {/* Feature 3 */}
            <div ref={setFeatureRef("feature3")}>
              <FeatureCard
                id="feature3"
                title="Know What You're Rendering"
                description="Eliminate unnecessary rendering by activating only the required layers and ensuring you output only what's essential."
                isClosest={closestFeatureId === "feature3"}
                opacity={closestFeatureId === "feature3" ? 1 : 0.3}
              >
                <ContentManagerMinimal
                  scale={scale}
                  active={closestFeatureId === "feature3"}
                />
              </FeatureCard>
            </div>

            {/* Feature 4 */}
            <div ref={setFeatureRef("feature4")}>
              <FeatureCard
                id="feature4"
                title="Include Your Own Extensions"
                description="Easily include your own Blender extensions to ensure compatibility on the farm."
                isClosest={closestFeatureId === "feature4"}
                opacity={closestFeatureId === "feature4" ? 1 : 0.3}
              >
                <Addons
                  scale={scale}
                  active={closestFeatureId === "feature4"}
                />
              </FeatureCard>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
});

export default BlenderExtensionFeatures;
