// components/KnowYourMachine.tsx
import React, { forwardRef } from "react";
import { UsersThree, MoneyWavy, Ranking } from "@phosphor-icons/react";
import TextCard from "../TextCard";
import Carousel from "../Carousel";

const knowYourMachineCards = [
  <TextCard
    key={1}
    icon={<UsersThree size={32} />}
    accentClass="text-emerald-600"
    text={
      <span>
        <strong>Market-driven peer-to-peer pricing</strong> guarantees
        unprecedented cost savings
      </span>
    }
  />,
  <TextCard
    key={2}
    icon={<MoneyWavy size={32} />}
    accentClass="text-emerald-600"
    text={
      <span>
        Optimized nodes enhance efficiency, directly{" "}
        <strong>lowering costs</strong>.
      </span>
    }
  />,
  <TextCard
    key={3}
    icon={<Ranking size={32} />}
    accentClass="text-emerald-600"
    text={
      <span>
        <strong>5,000 one-minute frames cost $25</strong>, making it the most
        affordable render farm ever.
      </span>
    }
  />,
];

const Pricing = forwardRef<HTMLDivElement>((props, ref) => {
  return (
    <div
      className="flex w-full flex-col items-center justify-center overflow-hidden pb-20"
      ref={ref}
    >
      <section className="relative flex w-full flex-col items-center justify-center overflow-hidden">
        <div className="z-10 w-full max-w-[90rem] px-4">
          <h2 className="pb-4 text-left text-2xl text-neutral-400 md:text-4xl">
            <span className="font-bold text-neutral-200">
              The most affordable render farm ever.
            </span>{" "}
            96% cheaper than leading render farms.
          </h2>
        </div>

        <section className="flex w-full max-w-[90rem] flex-col items-start overflow-visible bg-transparent px-4 pt-4">
          <Carousel slides={knowYourMachineCards} />
        </section>
      </section>
    </div>
  );
});

export default Pricing;
