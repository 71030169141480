// components/FeatureCard.tsx
import React from "react";
import DisplayCard from "./DisplayCard";

interface FeatureCardProps {
  id: string;
  title: string;
  description: string;
  children: React.ReactNode;
  isClosest: boolean;
  opacity: number;
}

const FeatureCard: React.FC<FeatureCardProps> = ({
  title,
  description,
  children,
  isClosest,
  opacity,
}) => {
  return (
    <div
      className="feature-card"
      style={{
        opacity: opacity,
        position: "relative",
        zIndex: 11, // Higher than the overlay's z-index
        transition: "opacity 0.5s",
      }}
    >
      <DisplayCard
        title={title}
        description={description}
        className="relative z-[11] transition-opacity duration-300"
        style={{ opacity: opacity }}
      >
        <div className="relative flex aspect-[8/3] w-full items-center justify-center overflow-hidden brightness-90">
          {children}
        </div>
      </DisplayCard>
    </div>
  );
};

export default FeatureCard;
