import React, { useRef, useEffect, useState } from "react";
import { File, Folder } from "@phosphor-icons/react";
import ScreenWindow from "../../ScreenWindow";
import DragAndDropImage from "../../../assets/images/features/drag_and_drop.png";
import Blender from "../../../assets/images/features/blender.png";
import { Package, CheckSquare } from "@phosphor-icons/react/dist/ssr";
import Meanderer from "../../utils/meanderer";

const FileIcon = ({ extension: name = "txt", className = "", style = {} }) => {
  return (
    <div
      className={`relative flex w-[700px] flex-row items-center justify-start rounded-md border-2 border-neutral-700 bg-neutral-800 px-2 py-1 text-neutral-600 ${className}`}
      style={{
        ...style,
      }}
    >
      <CheckSquare size={32} />
      <span className="pl-1 text-left text-2xl font-medium">{name}</span>
    </div>
  );
};

const UploadScreen = ({ className = "", style = {} }) => {
  const green900 = "#B11995";
  const green700 = "#911779";

  return (
    <div
      className={`relative flex h-full w-full flex-col items-center justify-center ${className}`} // Set a max width for the container using Tailwind
      style={{ ...style }}
    >
      <div className="flex h-full w-full flex-col items-center justify-center pb-20 text-neutral-700">
        <Package size={96} />
        <h2 className="text-5xl font-bold tracking-wide">Packing Addons...</h2>
        <div
          className="mt-4 h-7 w-2/3" // Converted background color, border radius, height, and margin top
        >
          <div
            style={{
              animation: "move-background 2s linear infinite",
              background: `linear-gradient(135deg, ${green700} 25%, ${green900} 25%, ${green900} 50%, ${green700} 50%, ${green700} 75%, ${green900} 75%, ${green900})`,
              backgroundSize: "28px 28px",
            }}
            className="animate-moveBackground h-full rounded-2xl bg-[length:28px_28px]"
          ></div>
        </div>
      </div>
      {/* Style for moving background animation */}
      <style>{`
        @keyframes move-background {
          0% { background-position: 0 0; }
          100% { background-position: 28px 0; }
        }
      `}</style>
    </div>
  );
};

interface FileType {
  color: string;
  Icon: React.ComponentType<{
    size: number;
    extension: string;
    className: string;
    style: React.CSSProperties;
  }>;
}

const fileTypes: Record<string, FileType> = {
  "Flip Fluids": { color: "text-red-500", Icon: FileIcon },
  "Scatter pro": { color: "text-green-500", Icon: FileIcon },
  "Auto-Rig Pro": { color: "text-blue-500", Icon: FileIcon },
  "Hard Ops": { color: "text-purple-500", Icon: FileIcon },
};

interface AddonsProps {
  scale: number;
  active: boolean;
}

const Addons: React.FC<AddonsProps> = ({ scale, active }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [files, setFiles] = useState<any[]>([]);
  const WIDTH = 100;
  const HEIGHT = 100;

  useEffect(() => {
    // const CONTAINER = containerRef.current;

    // if (CONTAINER) {
    // const width = CONTAINER.offsetWidth;
    // const height = CONTAINER.offsetHeight;
    const leftPadding = 5;
    const topPadding = 15;
    const spacing = 10;
    const iconSize = 1;

    // Generate files in a single column
    const generateFiles = () => {
      const newFiles = Object.keys(fileTypes).map((fileExtension, index) => {
        const fileType = fileTypes[fileExtension as keyof typeof fileTypes];

        // Since it's a single column, `col` is always 0 and row just becomes index
        const startX = WIDTH * 0.24; // Constant X position as all icons are in one column
        const startY = topPadding + index * spacing; // Increment Y position for each icon

        const endpointX = WIDTH * 0.75;
        const endpointY = 35;
        const breakPointX = endpointX - 10;

        const controlPointX = startX + (endpointX - startX) * 0.8; // Control point 75% along the X-axis
        const controlPointY = startY; // Same Y as start to initially keep the line horizontal

        const path = `M${startX},${startY} Q${controlPointX},${controlPointY} ${endpointX},${endpointY}`;

        // Initialize Meanderer
        const responsivePath = new Meanderer({
          path: path,
          width: WIDTH,
          height: HEIGHT,
        });

        const newPath = responsivePath.generatePath(1600, 600);

        // console.log(newPath);

        return {
          path: newPath,
          extension: fileExtension,
          color: fileType.color,
          Icon: fileType.Icon,
        };
      });
      return newFiles;
    };

    const initialFiles = generateFiles();
    setFiles(initialFiles);

    const updateFiles = () => {
      // console.log("updatePath");
      // console.log(CONTAINER.offsetWidth);
      // console.log(CONTAINER.offsetHeight);
      const updatedFiles = generateFiles();
      setFiles(updatedFiles);
    };

    updateFiles();

    // const resizeObserver = new ResizeObserver(updateFiles);
    // resizeObserver.observe(CONTAINER);

    // return () => {
    //   resizeObserver.disconnect();
    // };
    // }
  }, []);

  return (
    <div
      ref={containerRef}
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        transform: `scale(${scale})`,
        transformOrigin: "top left",
      }}
    >
      <div className="absolute flex w-[1600px] justify-start">
        <ScreenWindow
          variant="desktop"
          className="w-1/2 border-r border-neutral-800"
        >
          <div className="aspect-[4/3] h-full w-full" />
        </ScreenWindow>
        <div className="aspect-[4/3] w-1/2">
          <UploadScreen />
        </div>
      </div>
      {files.map((file, index) => (
        <div className="absolute bottom-0 left-0 m-0 h-full w-full">
          {/* <svg
            className="absolute left-0 top-0 h-full w-full stroke-neutral-200 stroke-2"
            style={{ width: "100%", height: "100%" }}
            // viewBox="0 0 100 100"
          >
            <path
              id="drag-path"
              d={file.path}
              fill="transparent"
              strokeWidth="2"
              strokeDasharray={15}
              strokeDashoffset={-5}
            />
          </svg> */}
          <file.Icon
            size={48}
            extension={file.extension}
            className={`offset-path`}
            style={{
              offsetPath: `path('${file.path}')`,
              offsetRotate: "0deg",
              // animation: `slideAddons 5s ease-in-out 3s infinite`, // Added 'infinite' and 'ease-in-out'
              animation: active
                ? `slideAddons ease-in-out 3s infinite`
                : "none",
              offsetDistance: "0%", // Initialize offset-distance
              transform: "scale(1)", // Initialize scale
            }}
          />
        </div>
      ))}
      <style>
        {`
    @keyframes slideAddons {
      0%, 100% {
        transform: scale(0); // Start and end scaled down (if you want it to disappear again at the end)
      }
      10% {

        transform: scale(1); // Scale to full size
      }
      20% {
        offset-distance: 0%; // Move to 20% of the path
        transform: scale(1); // Scale to full size
      
      }
      40% {
        transform: scale(1); // Scale to full size
      
      }
      75% {
        transform: scale(0.6); // Hold full size till 90%
      }
      100% {
        offset-distance: 100%; // Move to the end
        transform: scale(0); // Scale down to disappear

      }
  `}
      </style>
    </div>
  );
};
export default Addons;
