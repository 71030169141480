import React, { useEffect, useRef, useState } from "react";
import ScreenWindow from "../../ScreenWindow";
import DragAndDropImage from "../../../assets/images/features/drag_and_drop.png";
import Blender from "../../../assets/images/features/blender.png";
import Meanderer from "../../utils/meanderer";
import { ArrowsOutCardinal, HandGrabbing } from "@phosphor-icons/react";
import { HandPointing } from "@phosphor-icons/react";

const SimpleBlenderButton = ({ className = "", style = {} }) => {
  return (
    <div className={`relative inline-flex ${className}`} style={style}>
      <div
        className={
          "inline-flex items-center justify-center rounded-md border-2 border-violet-eggplant-600 bg-neutral-900 px-5 py-2 text-violet-eggplant-600 opacity-85"
        }
      >
        <div className="flex flex-row items-center space-x-1">
          <ArrowsOutCardinal size={20} />
          <span className="text-[0.75rem] font-bold">
            Drag and Drop into Blender
          </span>
        </div>
      </div>
      <HandGrabbing
        size={32}
        className="absolute -bottom-2 right-4 text-neutral-200 opacity-100"
        weight="fill"
      />
    </div>
  );
};

interface DragAndDropProps {
  scale: number;
  active: boolean;
}

const DragAndDrop: React.FC<DragAndDropProps> = ({ scale, active }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [scaledPath, setScaledPath] = useState<string>("M0,50 L100,50"); // Default path

  useEffect(() => {
    const PATH = "M15.5,41 A40,-70 0 0 1 75,50";

    const WIDTH = 100;
    const HEIGHT = 100;

    // Initialize Meanderer
    const responsivePath = new Meanderer({
      path: PATH,
      width: WIDTH,
      height: HEIGHT,
    });

    // Function to update the path on container resize
    const updatePath = () => {
      const newPath = responsivePath.generatePath(1600, 600);
      setScaledPath(newPath);
    };

    updatePath();
  }, []);

  return (
    <div
      ref={containerRef}
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        transform: `scale(${scale})`,
        transformOrigin: "top left",
      }}
    >
      <div className="absolute flex w-[1600px] justify-start">
        <ScreenWindow variant="desktop" className="w-1/2">
          <img
            src={DragAndDropImage}
            className="aspect-[4/3] h-full w-full brightness-75"
          />
        </ScreenWindow>
        <ScreenWindow variant="desktop" className="w-1/2">
          <img src={Blender} className="aspect-[4/3] h-full w-full" />
        </ScreenWindow>
      </div>
      <div className="absolute left-0 top-0 m-0 aspect-[8/3] w-[1600px]">
        <svg
          className="absolute left-0 top-0 h-full w-full stroke-neutral-200 stroke-2"
          style={{ width: "100%", height: "100%" }}
          // viewBox="0 0 100 100"
        >
          <path
            id="drag-path"
            d={scaledPath}
            fill="transparent"
            strokeWidth="7"
            strokeDasharray={30}
            strokeDashoffset={-5}
          />
        </svg>
        <SimpleBlenderButton
          // className="offset-path animate-slide"
          className={active ? "offset-path animate-slide" : "offset-path"}
          style={{ offsetPath: `path('${scaledPath}')`, offsetRotate: "0deg" }}
        />
      </div>
    </div>
  );
};

export default DragAndDrop;
