// ScreenWindow.tsx
import React from "react";

type ScreenWindowProps = React.HTMLAttributes<HTMLDivElement> & {
  variant: "ipad" | "desktop";
};

const ScreenWindow: React.FC<ScreenWindowProps> = ({
  variant,
  children,
  className = "",
  style,
  ...rest
}) => {
  if (variant === "ipad") {
    return (
      <div
        className={`relative overflow-visible rounded-[5%] bg-gradient-to-br from-neutral-600 via-neutral-500 to-neutral-700 p-[0.1%] ${className}`}
        style={style}
        {...rest}
      >
        <div className="h-full w-full rounded-[5%] bg-gradient-to-br from-neutral-900 to-neutral-950 p-[2%]">
          {/* iPad Frame */}
          <div className="overflow-hidden rounded-[2%]">{children}</div>
        </div>
      </div>
    );
  } else if (variant === "desktop") {
    return (
      <div
        className={`flex flex-col overflow-hidden rounded-lg ${className}`}
        style={style}
        {...rest}
      >
        {/* Title Bar */}
        <div className="flex items-center bg-neutral-800 px-3 py-1.5">
          <div className="flex space-x-2">
            <span className="h-3 w-3 rounded-full bg-red-500"></span>
            <span className="h-3 w-3 rounded-full bg-yellow-500"></span>
            <span className="h-3 w-3 rounded-full bg-green-500"></span>
          </div>
        </div>
        {/* Window Content */}
        <div className="flex-1 overflow-visible">{children}</div>
      </div>
    );
  }
  return null;
};

export default ScreenWindow;
