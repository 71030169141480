import React from "react";

interface TextCardProps {
  icon?: React.ReactNode;
  text: React.ReactNode;
  accentClass?: string; // Optional prop for accent class
  onClick?: () => void;
}

const TextCard: React.FC<TextCardProps> = ({
  icon,
  text,
  accentClass,
  onClick,
}) => {
  // Recursively clone and style strong tags with the accent class
  function styleStrongTags(node: React.ReactNode): React.ReactNode {
    return React.Children.map(node, (child) => {
      if (React.isValidElement(child)) {
        if (child.type === "strong") {
          return (
            <span className={accentClass}>
              {React.cloneElement(child, child.props)}
            </span>
          );
        } else if (child.props?.children) {
          return React.cloneElement(child, {
            ...child.props,
            children: styleStrongTags(child.props.children),
          });
        }
      }
      return child;
    });
  }

  const styledText = styleStrongTags(text);

  return (
    <div
      className="flex h-full w-full cursor-pointer flex-col justify-start text-neutral-400"
      onClick={onClick}
    >
      <div className="mb-4 flex items-center text-neutral-200">
        {icon && <div className={accentClass}>{icon}</div>}
      </div>
      <p className="text-left text-2xl">{styledText}</p>
    </div>
  );
};

export default TextCard;
