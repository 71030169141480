// components/TransferManager.tsx
import React, { forwardRef } from "react";
import {
  CloudArrowUp,
  CloudArrowDown,
  ShieldCheck,
  Lightning,
} from "@phosphor-icons/react";
import TextCard from "../TextCard";
import Carousel from "../Carousel";

const transferManagerCards = [
  <TextCard
    key={1}
    icon={<CloudArrowUp size={32} />}
    accentClass="text-sky-600"
    text={
      <span>
        <strong>Keep your uploads going</strong>, even after Blender shuts down.
      </span>
    }
  />,
  <TextCard
    key={2}
    icon={<CloudArrowDown size={32} />}
    accentClass="text-sky-600"
    text={
      <span>
        <strong>Download effortlessly in the background</strong>, without
        relying on the browser.
      </span>
    }
  />,
  <TextCard
    key={3}
    icon={<Lightning size={32} />}
    accentClass="text-sky-600"
    text={
      <span>
        <strong>Parallel frame downloads</strong> ensure peak download speeds
        and full network utilization.
      </span>
    }
  />,
  <TextCard
    key={4}
    icon={<ShieldCheck size={32} />}
    accentClass="text-sky-600"
    text={
      <span>
        Secure transfers with <strong>end-to-end encryption</strong>.
      </span>
    }
  />,
];

interface transferManagerProps {
  ref: React.RefObject<HTMLDivElement>;
}

const TransferManager = forwardRef<HTMLDivElement>((props, ref) => (
  <div
    className="flex w-full flex-col items-center justify-center overflow-hidden pb-20"
    ref={ref}
  >
    <section className="relative flex w-full flex-col items-center justify-center overflow-hidden">
      <div className="z-10 w-full max-w-[90rem] px-4">
        <h2 className="pb-4 text-left text-2xl text-neutral-400 md:text-4xl">
          <span className="font-bold text-neutral-200">Transfer Manager.</span>{" "}
          Manage your project files effortlessly with our robust system.
        </h2>
      </div>

      <section className="flex w-full max-w-[90rem] flex-col items-start overflow-visible bg-transparent px-4 pt-4">
        <Carousel slides={transferManagerCards} />
      </section>
    </section>
  </div>
));

export default TransferManager;
