// components/RenderViewerFeatures.tsx
import React, { forwardRef } from "react";
import Card from "../Card";
import HeroBanner from "../../assets/images/hero_banner.png";
import PlayBack from "../../assets/images/features/playback_full.png";
import Logs from "../../assets/images/features/logs.png";
import HeatMap from "../../assets/images/features/heatmap_full.png";
import Requeue from "../../assets/images/features/requeue.png";
import { Queue, TerminalWindow } from "@phosphor-icons/react";
import { IconCube3dSphere, IconPlayerPlay } from "@tabler/icons-react";
import ScreenWindow from "../ScreenWindow";
import TextCard from "../TextCard";
import Carousel from "../Carousel";
import { Stack } from "@phosphor-icons/react";
import { FileImage } from "@phosphor-icons/react";
import { motion } from "framer-motion";

const slides = [
  <TextCard
    key={1}
    icon={<TerminalWindow size={32} />}
    accentClass="text-violet-eggplant-600"
    text={
      <span>
        Get <strong>Render Logs</strong> of each frame to troubleshoot and
        optimize your renders.
      </span>
    }
  />,
  <TextCard
    key={2}
    icon={<Queue size={32} />}
    accentClass="text-violet-eggplant-600"
    text={
      <span>
        Use <strong>Advanced Requeuing</strong> to systematically requeue frames
        based on shared attributes.
      </span>
    }
  />,
  <TextCard
    key={3}
    icon={<Stack size={32} />}
    accentClass="text-violet-eggplant-600"
    text={
      <span>
        Freely render <strong>Multiple Render Layers</strong> and view them in
        the timeline.
      </span>
    }
  />,
  <TextCard
    key={4}
    icon={<FileImage size={32} />}
    accentClass="text-violet-eggplant-600"
    text={
      <span>
        See automatically generated thumbnails for{" "}
        <strong>All File Types</strong> in the browser, no exceptions.
      </span>
    }
  />,
];

interface renderViewerFeaturesProps {
  ref: React.RefObject<HTMLDivElement>;
}

const RenderViewerFeatures = forwardRef<HTMLDivElement>((props, ref) => (
  <div className="flex w-full flex-col items-center justify-center" ref={ref}>
    <section className="relative flex w-full items-center justify-center overflow-hidden">
      {/* Background Blur */}
      <div
        className="absolute inset-0 backdrop-blur-lg"
        style={{
          maskImage:
            "linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 10%, rgba(255,255,255,1) 90%,  rgba(255,255,255,0) 100%)",
          WebkitMaskImage:
            "linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 10%, rgba(255,255,255,1) 90%,  rgba(255,255,255,0) 100%)",
        }}
      ></div>

      <div className="flex w-full flex-col items-center justify-center">
        <div className="z-10 flex w-full max-w-[90rem] flex-col px-4 pb-12 pt-24 md:pb-24 md:pt-40">
          <h2 className="text-2xl text-neutral-400 md:text-4xl">
            <span className="font-bold text-neutral-200">
              An interface built to assist.
            </span>{" "}
            Speed up your workflow with stats and playback tools that make
            analyzing your renders both enjoyable and efficient.
          </h2>
        </div>

        <div className="z-10 grid w-full max-w-4xl grid-cols-1 gap-8 px-4 md:w-2/3 md:grid-cols-2 md:grid-rows-3 md:px-0">
          {/* Playback Screen */}
          <motion.div
            initial={{ scale: 0.8, opacity: 0 }}
            whileInView={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.5 }}
            viewport={{ once: false, amount: 0.5 }}
            className="relative flex flex-col items-end justify-end md:order-1 md:col-start-1 md:row-span-2 md:row-start-1"
          >
            <div className="relative box-border block aspect-[4/3] h-full">
              <ScreenWindow variant="ipad" className="relative h-full">
                <img
                  title="In-depth Playback Tools"
                  src={PlayBack}
                  className="relative h-full w-full"
                />
              </ScreenWindow>
            </div>
          </motion.div>

          {/* Playback Text */}
          <motion.div
            initial={{ scale: 0.8, opacity: 0 }}
            whileInView={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.5 }}
            viewport={{ once: false, amount: 0.5 }}
            className="flex items-center justify-start pl-2 md:order-2 md:col-start-2 md:row-start-1"
          >
            <div className="md:my-24 md:pt-8">
              <div className="flex flex-row space-x-2 text-violet-eggplant-600">
                <IconPlayerPlay />
                <h2 className="text-md pb-2 font-extrabold tracking-wider">
                  Playback Tools
                </h2>
              </div>

              <p className="pb-8 text-xl font-bold tracking-wide text-neutral-200 md:pb-4 md:text-3xl">
                Play, pause, and scrub through your renders with a playback bar
                that displays the progress of each frame.
              </p>
            </div>
          </motion.div>

          {/* Frame Matrix Screen */}
          <motion.div
            initial={{ scale: 0.8, opacity: 0 }}
            whileInView={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.5 }}
            viewport={{ once: false, amount: 0.5 }}
            className="relative box-border justify-end overflow-visible md:order-4 md:col-start-2 md:row-span-2 md:row-start-2"
          >
            <div className="box-border block aspect-[4/3] h-full">
              <ScreenWindow variant="desktop" className="relative h-full">
                <img
                  title="Frame Matrix"
                  src={HeatMap}
                  className="relative h-full w-full"
                />
              </ScreenWindow>
            </div>
          </motion.div>

          {/* Frame Matrix Text */}
          <motion.div
            initial={{ scale: 0.8, opacity: 0 }}
            whileInView={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.5 }}
            viewport={{ once: false, amount: 0.5 }}
            className="flex items-center justify-end pr-2 md:order-3 md:col-start-1 md:row-start-3"
          >
            <div className="md:my-24">
              <div className="flex flex-row space-x-2 text-violet-eggplant-600">
                <IconCube3dSphere />
                <h2 className="text-md pb-2 font-extrabold tracking-wider">
                  Frame Matrix
                </h2>
              </div>
              <p className="text-xl font-bold tracking-wide text-neutral-200 md:text-3xl">
                The Frame Matrix offers detailed insights into your renders for
                detailed render time diagnostics.
              </p>
            </div>
          </motion.div>
        </div>
      </div>
    </section>

    <section className="flex w-full flex-col items-center overflow-hidden bg-transparent px-4 pt-16 md:pt-24">
      <div className="w-full max-w-[90rem]">
        <Carousel slides={slides} />
      </div>
    </section>
  </div>
));

export default RenderViewerFeatures;
