// components/Footer.tsx
import React from "react";

const Footer: React.FC = () => {
  return (
    <footer className="mt-40 bg-neutral-900 p-4 text-neutral-300 md:p-8">
      <div className="mx-auto flex max-w-7xl flex-col items-center justify-between md:flex-row">
        <div className="mb-4 md:mb-0">
          <p>
            OctaRender is part of{" "}
            <a
              href="https://octa.space"
              className="text-violet-eggplant-500 hover:text-violet-eggplant-400"
            >
              Octa.Space
            </a>
          </p>
        </div>
        <div className="mb-4 flex space-x-4 md:mb-0">
          <a href="https://x.com/octa_space" className="hover:text-neutral-200">
            X
          </a>
          <a
            href="https://github.com/OctaSpace"
            className="hover:text-neutral-200"
          >
            Github
          </a>
          <a
            href="https://discord.gg/OctaSpace"
            className="hover:text-neutral-200"
          >
            Discord
          </a>
          <a href="https://t.me/octa_space" className="hover:text-neutral-200">
            Telegram
          </a>
          <a
            href="https://reddit.com/r/OctaSpace"
            className="hover:text-neutral-200"
          >
            Reddit
          </a>
          <a
            href="https://www.youtube.com/@octa_space"
            className="hover:text-neutral-200"
          >
            Youtube
          </a>
        </div>
        <div className="text-sm">
          © 2022-2024 All rights reserved by OctaSpace
        </div>
      </div>
    </footer>
  );
};

export default Footer;
