import React, { useRef, useEffect, useState } from "react";
import { File, Folder } from "@phosphor-icons/react";
import ScreenWindow from "../../ScreenWindow";
import DragAndDropImage from "../../../assets/images/features/drag_and_drop.png";
import Blender from "../../../assets/images/features/blender.png";
import { Package } from "@phosphor-icons/react/dist/ssr";
import Meanderer from "../../utils/meanderer";

const FileIcon = ({
  size = 96,
  extension = "txt",
  className = "",
  style = {},
}) => {
  return (
    <div
      className={`relative flex items-center justify-center ${className}`}
      style={{ ...style, width: size, height: size }} // Ensure the container is square based on the size prop
    >
      <File
        size={size}
        weight="fill"
        // className="brightness-75"
        // weight="fill"
        // strokeWidth={12}
      />
      <div
        className={`absolute bottom-2 left-0 right-0 flex items-end justify-center text-lg font-bold text-neutral-800`}
        style={{ height: "100%" }} // Use 100% height for vertical centering
      >
        .{extension}
      </div>
    </div>
  );
};

const UploadScreen = ({ className = "", style = {} }) => {
  const green900 = "#B11995";
  const green700 = "#911779";

  return (
    <div
      className={`relative flex h-full w-full flex-col items-center justify-center ${className}`} // Set a max width for the container using Tailwind
      style={{ ...style }}
    >
      <div className="flex h-full w-full flex-col items-center justify-center pb-20 text-neutral-700">
        <Package size={96} />
        <h2 className="text-5xl font-bold tracking-wide">Packing Files...</h2>
        <div
          className="mt-4 h-7 w-2/3" // Converted background color, border radius, height, and margin top
        >
          <div
            style={{
              animation: "move-background 2s linear infinite",
              background: `linear-gradient(135deg, ${green700} 25%, ${green900} 25%, ${green900} 50%, ${green700} 50%, ${green700} 75%, ${green900} 75%, ${green900})`,
              backgroundSize: "28px 28px",
            }}
            className="animate-moveBackground h-full rounded-2xl bg-[length:28px_28px]"
          ></div>
        </div>
      </div>
      {/* Style for moving background animation */}
      <style>{`
        @keyframes move-background {
          0% { background-position: 0 0; }
          100% { background-position: 28px 0; }
        }
      `}</style>
    </div>
  );
};

const FolderIcon = ({ size = 64, className = "", style = {} }) => {
  return (
    <div
      className={`relative flex items-center justify-center ${className}`}
      style={{ ...style, width: size, height: size }} // Ensure the container is square based on the size prop
    >
      <Folder size={size} weight="fill" />
    </div>
  );
};

interface FileType {
  color: string;
  Icon: React.ComponentType<{
    size: number;
    extension: string;
    className: string;
    style: React.CSSProperties;
  }>;
}

const fileTypes: Record<string, FileType> = {
  png: { color: "text-red-500", Icon: FileIcon },
  jpg: { color: "text-green-500", Icon: FileIcon },
  exr: { color: "text-blue-500", Icon: FileIcon },
  folder: { color: "text-neutral-500", Icon: FolderIcon },
  vdb: { color: "text-purple-500", Icon: FileIcon },
  mp4: { color: "text-yellow-500", Icon: FileIcon },
  usd: { color: "text-orange-500", Icon: FileIcon },
  blend: { color: "text-violet-500", Icon: FileIcon },
  tiff: { color: "text-cyan-500", Icon: FileIcon },
};

interface ContentManagerMinimalProps {
  scale: number;
  active: boolean;
}

const Dependencies: React.FC<ContentManagerMinimalProps> = ({
  scale,
  active,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [files, setFiles] = useState<any[]>([]);
  const WIDTH = 100;
  const HEIGHT = 100;

  useEffect(() => {
    // const CONTAINER = containerRef.current;

    // if (CONTAINER) {
    // const width = CONTAINER.offsetWidth;
    // const height = CONTAINER.offsetHeight;

    const leftPadding = 5;
    const topPadding = 18;
    const spacing = 7;
    const maxRowWidth = 6;

    // Generate files without iterating through grid rows and columns
    const generateFiles = () => {
      const newFiles = Object.keys(fileTypes).map((fileExtension, index) => {
        const fileType = fileTypes[fileExtension as keyof typeof fileTypes];

        // Calculate row and column based on index
        const row = Math.floor(index / maxRowWidth);
        const col = index % maxRowWidth;

        const startX = leftPadding + col * spacing; // Adjust spacing based on icon size
        const startY = topPadding + row * (spacing + 10); // Adjust vertical spacing based on icon size
        const endpointX = WIDTH * 0.75;
        const endpointY = 40;
        const path = `M${startX},${startY} L${endpointX},${endpointY}`;

        // Initialize Meanderer
        const responsivePath = new Meanderer({
          path: path,
          width: WIDTH,
          height: HEIGHT,
        });

        const newPath = responsivePath.generatePath(1600, 600);

        console.log(newPath);

        return {
          path: newPath,
          extension: fileExtension,
          color: fileType.color,
          Icon: fileType.Icon,
        };
      });
      return newFiles;
    };

    const initialFiles = generateFiles();
    setFiles(initialFiles);

    const updateFiles = () => {
      const updatedFiles = generateFiles();
      setFiles(updatedFiles);
    };

    updateFiles();

    // const resizeObserver = new ResizeObserver(updateFiles);
    // resizeObserver.observe(CONTAINER);

    // return () => {
    //   resizeObserver.disconnect();
    // };
    // }
  }, []);

  return (
    <div
      ref={containerRef}
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        transform: `scale(${scale})`,
        transformOrigin: "top left",
      }}
    >
      <div className="absolute flex w-[1600px] justify-start">
        <ScreenWindow
          variant="desktop"
          className="w-1/2 border-r border-neutral-800"
        >
          <div className="bg-neutral- aspect-[4/3] h-full w-full" />
        </ScreenWindow>
        <div className="aspect-[4/3] w-1/2">
          <UploadScreen />
        </div>
      </div>
      {files.map((file, index) => (
        <div className="absolute left-0 top-0 m-0 aspect-[8/3] w-[1600px]">
          {/* <svg
            className="absolute left-0 top-0 h-full w-full stroke-neutral-200 stroke-2"
            style={{ width: "100%", height: "100%" }}
            // viewBox="0 0 100 100"
          >
            <path
              id="drag-path"
              d={file.path}
              fill="transparent"
              strokeWidth="2"
              strokeDasharray={15}
              strokeDashoffset={-5}
            />
          </svg> */}
          <file.Icon
            size={96}
            extension={file.extension}
            className={`offset-path size-12 ${file.color}`}
            // className="offset-path size-12 animate-slide text-neutral-600"
            style={{
              offsetPath: `path('${file.path}')`,
              offsetRotate: "0deg",
              // animation: `slide 5s ease-in-out 3s infinite`, // Added 'infinite' and 'ease-in-out'
              animation: active ? `slide ease-in-out 3s infinite` : "none",
              offsetDistance: "0%", // Initialize offset-distance
              transform: "scale(1)", // Initialize scale
            }}
          />
        </div>
      ))}
      <style>
        {`
    @keyframes slide {
      0%, 100% {
        transform: scale(0); // Start and end scaled down (if you want it to disappear again at the end)
      }
      10% {

        transform: scale(1); // Scale to full size
      }
      20% {
        offset-distance: 0%; // Move to 20% of the path
      }
      75% {
        transform: scale(1); // Hold full size till 90%
      }
      100% {
        offset-distance: 100%; // Move to the end
        transform: scale(0); // Scale down to disappear

      }
  `}
      </style>
    </div>
  );
};
export default Dependencies;
