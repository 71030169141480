// components/KnowYourMachine.tsx
import React, { forwardRef } from "react";
import {
  Cpu,
  GraphicsCard,
  HardDrives,
  Gauge,
  Lightning,
  RocketLaunch,
  Rocket,
} from "@phosphor-icons/react";
import TextCard from "../TextCard";
import Carousel from "../Carousel";

const unparalleledPerformanceCards = [
  <TextCard
    key={1}
    icon={<Lightning size={32} />}
    accentClass="text-emerald-600"
    text={
      <span className="text-neutral-400">
        Experience{" "}
        <strong className="text-white">blazing-fast rendering</strong> speeds
        that outpace traditional farms.
      </span>
    }
  />,
  <TextCard
    key={2}
    icon={<RocketLaunch size={32} />}
    accentClass="text-amber-600"
    text={
      <span className="text-neutral-400">
        Boost productivity with our{" "}
        <strong className="text-white">optimized infrastructure</strong>.
      </span>
    }
  />,
  <TextCard
    key={3}
    icon={<Gauge size={32} />}
    accentClass="text-sky-600"
    text={
      <span className="text-neutral-400">
        Achieve <strong className="text-white">unmatched performance</strong> on
        every project.
      </span>
    }
  />,
  <TextCard
    key={4}
    icon={<Cpu size={32} />}
    accentClass="text-violet-eggplant-600"
    text={
      <span className="text-neutral-400">
        Harness the power of{" "}
        <strong className="text-white">state-of-the-art hardware</strong>.
      </span>
    }
  />,
];

const knowYourMachineCards = [
  <TextCard
    key={1}
    icon={<Rocket size={32} />}
    accentClass="text-amber-600"
    text={
      <span>
        Up to <strong>3x</strong> faster renders than native{" "}
        <strong>Cycles</strong> on the same hardware.
      </span>
    }
  />,
  <TextCard
    key={2}
    icon={<Lightning size={32} />}
    accentClass="text-amber-600"
    text={
      <span>
        Multi GPU Eevee renders deliver speeds up to{" "}
        <strong>8x faster than native Eevee</strong> on the same hardware.
      </span>
    }
  />,
  <TextCard
    key={3}
    icon={<Gauge size={32} />}
    accentClass="text-amber-600"
    text={
      <span className="text-neutral-400">
        Speed up your renders by over <strong>1000x</strong> compared to
        rendering at home.
      </span>
    }
  />,
];

interface renderNodePerformanceProps {
  ref: React.RefObject<HTMLDivElement>;
}

const RenderNodePerformance = forwardRef<HTMLDivElement>((props, ref) => (
  <div
    className="flex w-full flex-col items-center justify-center overflow-hidden pb-20"
    ref={ref}
  >
    <section className="relative flex w-full flex-col items-center justify-center overflow-hidden">
      <div className="z-10 w-full max-w-[90rem] px-4">
        <h2 className="pb-4 text-left text-2xl text-neutral-400 md:text-4xl">
          <span className="font-bold text-neutral-200">
            Unparalleled Performance.
          </span>{" "}
          Render faster than ever before.
        </h2>
      </div>

      <section className="flex w-full max-w-[90rem] flex-col items-start overflow-visible bg-transparent px-4 pt-4">
        <Carousel slides={knowYourMachineCards} />
      </section>
    </section>
  </div>
));

export default RenderNodePerformance;
